import CenteredTypography from './CenteredTypography'
import GridItem from './GridItem'

const billingNumber = '256-854-9989'

export default function GridItemContactUs() {
  return (
    <GridItem>
      <br />
      <CenteredTypography variant='h6'>Questions about your bill?</CenteredTypography>
      <CenteredTypography variant='h6'>Call us at <a href={`tel:${billingNumber}`}>{billingNumber}</a></CenteredTypography>
    </GridItem>
  )
}