import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography } from '@mui/material'

export default function ConfirmCardDialog({ isLoading, gotError, externalIsOpen, cardNumberLastFour, handleSubmit }) {
  const [isOpen, setIsOpen] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setIsOpen(!!externalIsOpen)
  }, [externalIsOpen])

  useEffect(() => {
    setShowError(gotError)
  }, [gotError])

  const handleClose = () => setIsOpen(false)

  return (
    <Dialog maxWidth='xs' fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Confirm Payment
      </DialogTitle>
      <DialogContent>
        <Typography>Pay with Card *{cardNumberLastFour}?</Typography>
        {showError && <Typography color='error'>There was an error processing your payment. Please try again or pay with a different card.</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button disabled={isLoading} variant='contained' onClick={handleSubmit}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}