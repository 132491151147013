import Home from './page/Home'
import Complete from './page/Complete'
import LegacyCheck from './page/LegacyCheck'

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: ':visitId',
    element: <Home />
  },
  {
    path: '/complete/:clinicType',
    element: <Complete />
  },
  {
    path: '/legacy-check',
    element: <LegacyCheck />
  }
]

export default AppRoutes