import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography } from '@mui/material'
import Cards from 'react-credit-cards-2'
import Payment from 'payment'
import 'react-credit-cards-2/es/styles-compiled.css'

export default function NewCardDialog({ isLoading, gotError, externalIsOpen, windowSize, handleSubmit }) {
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvc, setCvc] = useState('')
  const [cardFocus, setCardFocus] = useState('')
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setIsOpen(!!externalIsOpen)
  }, [externalIsOpen])

  useEffect(() => {
    setShowError(gotError)
  }, [gotError])

  const handleName = (v) => {
    v = v.substring(0, 30)

    setName(v)
  }

  const handleNumber = (v) => {
    const formatted = formatCreditCardNumber(v)

    setNumber(formatted)
  }

  const handleExpiry = (v) => {
    const formatted = formatExpiry(v)

    setExpiry(formatted)
  }

  const handleCvc = (v) => {
    const formatted = formatCvc(v)

    setCvc(formatted)
  }

  const handleFocus = (e) => setCardFocus(e.target.name)

  const handleClose = () => setIsOpen(false)

  const clearNumber = (value = '') => {
    return value.replace(/\D/g, '')
  }

  const formatCreditCardNumber = (v) => {
    if (!v) {
      return v
    }
  
    const issuer = Payment.fns.cardType(v)
    const clearValue = clearNumber(v)
    let nextValue
  
    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
        break
      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
        break
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
        break
    }

    return nextValue.trim()
  }

  const formatExpiry = (v) => {
    const clearValue = clearNumber(v)

    if(clearValue.length < 3) {
      return clearValue
    }

    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  const formatCvc = (v) => {
    const clearValue = clearNumber(v)
    let maxLength = 4
  
    if (number) {
      const issuer = Payment.fns.cardType(number)
      maxLength = issuer === 'amex' ? 4 : 3
    }
  
    return clearValue.slice(0, maxLength)
  }

  const isNameValid = name.length > 0
  const isNumberValid = Payment.fns.validateCardNumber(number)
  const isExpiryValid = Payment.fns.validateCardExpiry(expiry)
  const isCvcValid = Payment.fns.validateCardCVC(cvc)
  const isValid = isNameValid && isNumberValid && isExpiryValid && isCvcValid

  return (
    <Dialog fullScreen={windowSize.x < 402} open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Enter Payment Information
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <br />
            <Cards
              name={name}
              number={number}
              expiry={expiry}
              cvc={cvc}
              focused={cardFocus} />
            <br />
          </Grid>
          {showError &&
            <>
              <Grid item xs={12}><Typography color='error'>There was an error processing your payment. Please verify the info you provided is correct and try again.</Typography></Grid>
              <Grid item xs={12}><br /></Grid>
            </>
          }
          <GridTextField name='name' value={name} label='Card Holder Name' handleChange={handleName} handleFocus={handleFocus} />
          <Grid item xs={12}><br /></Grid>
          <GridTextField name='number' value={number} label='Card Number' handleChange={(v) => handleNumber(v)} handleFocus={handleFocus} isNumericInput />
          <Grid item xs={12}><br /></Grid>
          <GridTextField name='expiry' value={expiry} label='Expiration Date' handleChange={(v) => handleExpiry(v)} handleFocus={handleFocus} isNumericInput placeholder='MM/YY' />
          <Grid item xs={12}><br /></Grid>
          <GridTextField name='cvc' value={cvc} label='CVC/CVV' handleChange={(v) => handleCvc(v)} handleFocus={handleFocus} isNumericInput />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Back</Button>
        <Button variant='contained' disabled={!isValid || isLoading} onClick={() => handleSubmit(name, number, expiry, cvc)}>Pay</Button>
      </DialogActions>
    </Dialog>
  )
}

const GridTextField = ({ name, value, label, handleChange, handleFocus, isNumericInput, placeholder }) => {
  return (
    <Grid item xs={12}>
      <TextField
        name={name}
        value={value}
        label={label}
        inputProps={{ inputMode: isNumericInput ? 'numeric' : 'text' }}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        fullWidth
        placeholder={placeholder} />
    </Grid>
  )
}