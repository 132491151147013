import { useState } from 'react'
import { useParams } from 'react-router-dom'

import FindStatement from './component/FindStatement'
import VerifyPhoneNumber from './component/VerifyPhoneNumber'
import ViewStatement from './component/ViewStatement'

export default function Home() {
  const { visitId } = useParams()
  const [phoneNumberLastFour, setPhoneNumberLastFour] = useState('')
  const [info, setInfo] = useState(null)

  if(!phoneNumberLastFour) {
    return <FindStatement passedVisitId={visitId} setPhoneNumberLastFour={setPhoneNumberLastFour} />
  }

  if(!info) {
    return <VerifyPhoneNumber phoneNumberLastFour={phoneNumberLastFour} setInfo={setInfo} />
  }

  return <ViewStatement info={info} />
}