import { Grid } from '@mui/material'

export default function GridCenter({ children, width }) {
  const border = (12 - width) / 2

  return (
    <Grid container>
      <Grid item xs={border} />
      <Grid item xs={width}>{children}</Grid>
      <Grid item xs={border} />
    </Grid>
  )
}