const getCall = async(url) => {
  for(let i = 0; i < 3; i++) {
    try {
      const response = await fetch(url)
    
      return await response.json()
    }
    catch(e) {
      console.error(e)
    }
  }
}

const postCall = async(url, body) => {
  for(let i = 0; i < 3; i++) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })

      return await response.json()
    }
    catch(e) {
      console.error(e)
    }
  }
}

const ApiClient = {
  listClinic: async() => getCall('API/ListClinic'),
  checkIfLegacy: async(key, date) => postCall('API/CheckIfLegacy', { key, date }),
  checkWithId: async(visitId) => postCall('API/CheckWithId', { visitId }),
  checkWithDocutapVisitId: async(docutapVisitId) => postCall('API/CheckWithDocutapVisitId', { docutapVisitId }),
  verifyPhoneNumber: async(code) => postCall('API/VerifyPhoneNumber', { code }),
  payWithFiledCard: async() => postCall('API/PayWithFiledCard', undefined),
  payWithNewCard: async(name, number, expiry, cvc) => postCall('API/PayWithNewCard', { name, number, expiry, cvc })
}

export default ApiClient