import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'

export default function DatePicker ({ value, onChange }) {
  const formatDate = (v) => {
    if (!v) {
      return ['', false]
    }

    v = v.replace(/\D/g, '')
    v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)}`
    v = v.replace(/\/*$/, '').trim()

    const isValid = v && v.length === 10 && dayjs(v, 'MM/DD/YYYY', true).isValid()

    return [v, isValid]
  }

  return (
    <TextField
      fullWidth
      value={value}
      placeholder='MM/DD/YYYY'
      onChange={v => onChange(formatDate(v.target.value))}
      inputProps={{ inputMode: 'numeric' }} />
  )
}