import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Fade,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell } from '@mui/material'
import dayjs from 'dayjs'

import CenteredTypography from './CenteredTypography'
import ConfirmCardDialog from './ConfirmCardDialog'
import GridImage from './GridImage'
import GridItem from './GridItem'
import GridItemContactUs from './GridItemContactUs'
import NewCardDialog from './NewCardDialog'
import ApiClient from '../client/ApiClient'
import Timeout from '../utility/Timeout'
import TransitionTime from '../utility/TransitionTime'

export default function ViewStatement ({ info }) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isNewPaymentDialogOpen, setIsNewPaymentDialogOpen] = useState(false)
  const [fadeIn, setFadeIn] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [gotError, setGotError] = useState(false)
  const [windowSize, setWindowSize] = useState({ x: 0, y: 0 })
  
  useEffect(() => {
    function updateSize() {
      setWindowSize({
        x: window.innerWidth,
        y: window.innerHeight
      })
    }
    
    window.addEventListener('resize', updateSize)
    updateSize()
    
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  
  const formatDate = (date) => {
    if(!date.endsWith('Z')) {
      date += 'Z'
    }

    return dayjs(date).format('M/D/YYYY')
  }
  
  const handlePayWithFiledCard = async () => {
    setIsLoading(true)
    setGotError(false)
    const response = await ApiClient.payWithFiledCard()

    if(response && response.error) {
      setIsLoading(false)
      setGotError('FiledCard')
      return
    }

    transitionToCompletePage()
  }

  const handlePayWithNewCard = async (name, number, expiry, cvc) => {
    setIsLoading(true)
    setGotError(false)
    const response = await ApiClient.payWithNewCard(name, number, expiry, cvc)

    if(response && response.error) {
      setIsLoading(false)
      setGotError('NewCard')
      return
    }

    transitionToCompletePage()
  }

  const transitionToCompletePage = async () => {
    setFadeIn(false)
    setIsConfirmDialogOpen(false)
    setIsNewPaymentDialogOpen(false)
    await Timeout(TransitionTime)
    window.location.href = `/complete/${info.clinicType}`
  }

  return (
    <>
      <Fade in={fadeIn} timeout={TransitionTime}>
        <Grid container>
          <GridItem>
            <GridImage name={info.clinicType === 'MainStreet' ? 'MainStreet.png' : 'KidsStreet.png'} />
          </GridItem>
          <GridItem>
            <CenteredTypography><br />Hi, {info.name}!</CenteredTypography>
          </GridItem>
          <GridItem>
            <CenteredTypography>Here is your statement from your visit on {formatDate(info.date)}</CenteredTypography>
          </GridItem>
          { info.allowPayment &&
            <>
              <GridItem>
                <br />
                <div className='circle-box'>
                  <div className='circle'>
                    <div>
                      <CenteredTypography variant='h6'>Your Balance</CenteredTypography>
                      <CenteredTypography variant='h3'>{info.balance}</CenteredTypography>
                    </div>
                  </div>
                </div>
                <br />
              </GridItem>
              <GridItem>
                { info.cardNumberLastFour ?
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Box marginX={1} marginBottom={1}>
                        <Button size='large' fullWidth variant='contained' onClick={() => setIsConfirmDialogOpen(new Date())}>Pay now with Card *{info.cardNumberLastFour}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box marginX={1} marginBottom={1}>
                        <Button size='large' fullWidth variant='contained' onClick={() => setIsNewPaymentDialogOpen(new Date())}>Pay now with a different card</Button>
                      </Box>
                    </Grid>
                  </Grid>
                  :
                  <Box marginX={1} marginBottom={1}>
                    <Button size='large' fullWidth variant='contained' onClick={() => setIsNewPaymentDialogOpen(new Date())}>Pay now with a card</Button>
                  </Box>
                }
              </GridItem>
            </>
          }
          <GridItem>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Date</strong></TableCell>
                  <TableCell><strong>Description</strong></TableCell>
                  <TableCell><strong>Amount</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.lineItems.map(x => {
                  return (
                    <TableRow key={x.id}>
                      <TableCell>{formatDate(x.date)}</TableCell>
                      <TableCell>{x.description}</TableCell>
                      <TableCell>{x.amount}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell colSpan={3}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell>{info.balance}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
          <GridItemContactUs />
        </Grid>
      </Fade>
      <ConfirmCardDialog isLoading={isLoading} gotError={gotError === 'FiledCard'} externalIsOpen={isConfirmDialogOpen} cardNumberLastFour={info.cardNumberLastFour} handleSubmit={handlePayWithFiledCard} />
      <NewCardDialog isLoading={isLoading} gotError={gotError === 'NewCard'} externalIsOpen={isNewPaymentDialogOpen} windowSize={windowSize} handleSubmit={handlePayWithNewCard} />
    </>
  )
}