import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './App.css'

export default class App extends Component {
  static displayName = App.name

  render() {
    const theme = createTheme({
      palette: {
        primary: {
          main: '#355A99'
        }
      }
    })

    return (
      <ThemeProvider theme={theme}>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route
            return <Route key={index} {...rest} element={element} />
          })}
        </Routes>
      </ThemeProvider>
    )
  }
}