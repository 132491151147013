import { Grid } from '@mui/material'

export default function GridItem({ children }) {
  return (
    <>
      <Grid item xs={0} sm={3} />
      <Grid item xs={12} sm={6}>{children}</Grid>
      <Grid item xs={0} sm={3} />
    </>
  )
}