import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import NativeSelect from '@mui/material/NativeSelect'
import dayjs from 'dayjs'

import CenteredTypography from './component/CenteredTypography'
import GridCenter from './component/GridCenter'
import GridItem from './component/GridItem'
import GridItemContactUs from './component/GridItemContactUs'
import GridImage from './component/GridImage'
import DatePicker from './component/DatePicker'
import ApiClient from './client/ApiClient'
import TransitionTime from './utility/TransitionTime'

export default function LegacyCheck() {
  const [clinics, setClinics] = useState(null)
  const [selectedClinic, setSelectedClinic] = useState('')
  const [[date, isDateValid], setDate] = useState(['', false])
  const [fadeIn, setFadeIn] = useState(true)
  const isValid = selectedClinic && isDateValid

  useEffect(() => {
    load()
  }, [])

  const load = async() => {
    const response = await ApiClient.listClinic()

    setClinics(response)
  }

  const handleNext = async () => {
    setFadeIn(false)
    const formattedDate = dayjs(date).format('YYYY-MM-DD')

    const response = await ApiClient.checkIfLegacy(selectedClinic, formattedDate)

    console.log(response.isLegacy)

    if(response.isLegacy) {
      window.location.href = 'https://www.patientnotebook.com/mainstreet'
      return
    }

    window.location.href = '/'
  }

  if(!clinics) {
    return (
      <Wrapper fadeIn={fadeIn}>
        <GridItem>
          <br />
          <CenteredTypography>Loading...</CenteredTypography>
        </GridItem>
      </Wrapper>
    )
  }

  return (
    <Wrapper fadeIn={fadeIn}>
      <GridItem>
        <br />
        <CenteredTypography>Which clinic were you seen at?</CenteredTypography>
      </GridItem>
      <GridItem>
        <GridCenter width={4}>
          <FormControl fullWidth>
            <InputLabel variant='standard'>Clinic</InputLabel>
            <NativeSelect defaultValue='' onChange={(e) => setSelectedClinic(e.target.value)}>
              <option hidden value=''></option>
              {clinics.map(x => <option key={x.key} value={x.key}>{x.name}</option>)}
            </NativeSelect>
          </FormControl>
        </GridCenter>
      </GridItem>
      <GridItem>
        <br />
        <CenteredTypography>When were you seen?</CenteredTypography>
      </GridItem>
      <GridItem>
        <GridCenter width={4}>
          <DatePicker value={date} onChange={v => setDate(v)} />
        </GridCenter>
      </GridItem>
      <GridItem>
        <br />
        <GridCenter width={2}>
          <Button disabled={!isValid} fullWidth variant='contained' onClick={() => handleNext()}>Next</Button>
        </GridCenter>
      </GridItem>
      <GridItemContactUs />
    </Wrapper>
  )
}

const Wrapper = ({ fadeIn, children }) => {
  return (
    <Fade in={fadeIn} timeout={TransitionTime}>
      <Grid container>
        <GridItem>
          <GridImage name='MainStreet.png' />
        </GridItem>
        <GridItem>
          <GridImage name='KidsStreet.png' />
        </GridItem>
        {children}
      </Grid>
    </Fade>
  )
}